var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mt-3 vx-row" }, [
      _c(
        "header",
        {
          staticClass: "w-full mb-4 vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
        },
        [
          _vm.type === "csv"
            ? _c("h4", [_vm._v(_vm._s(_vm.$t("apps.photos.exportcsv")))])
            : _vm.type === "zip"
            ? _c("h4", [_vm._v(_vm._s(_vm.$t("apps.photos.exportzip")))])
            : _vm._e(),
          _c("p", [_vm._v(_vm._s(_vm.$t("apps.photos.exportdescrform")))])
        ]
      )
    ]),
    _c(
      "div",
      [
        _c("ExportForm", {
          attrs: { buttonText: _vm.$t("apps.photos.exportdescr") },
          on: { exportData: _vm.exportData }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }