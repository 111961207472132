<template>
  <ExportPhotosPage :photos-list-route-name="photosListRouteName" :export-photos-function="exportPhotosFunction" :type="this.type" />
</template>

<script>

import PhotoService from '@/modules/Resorts/Services/PhotoService'
import ExportPhotosPage from "@/modules/Shared/Components/photos/ExportPhotosPage";

export default {
  name: 'resort-photos-export-form',
  components: {
    ExportPhotosPage
  },
  data () {
    return {
      photosListRouteName: 'export-resort-photos',
      exportPhotosFunction: PhotoService.exportPhotos
    }
  },
  props: {
    type: {
      type: String,
      default: 'csv'
    }
  }
}
</script>
