<template>
  <div>
    <div class="mt-3 vx-row">
      <header class="w-full mb-4 vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
        <h4 v-if="type === 'csv'">{{ $t("apps.photos.exportcsv") }}</h4>
        <h4 v-else-if="type === 'zip'">{{ $t("apps.photos.exportzip") }}</h4>
        <p>{{ $t("apps.photos.exportdescrform") }}</p>
      </header>
    </div>
    <div>
      <ExportForm :buttonText="$t('apps.photos.exportdescr')" @exportData="exportData" />
    </div>
  </div>
</template>

<script>

import ExportForm from '@/modules/Shared/Components/form/ExportForm'
import notifications from "@/modules/Shared/Mixins/notifications";
import loader from "@/modules/Shared/Mixins/loader";

export default {
  name: 'tracks-export-page',
  components: {
    ExportForm
  },
  props: {
    exportPhotosFunction: {
      type: Function,
      required: true
    },
    photosListRouteName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'csv'
    }
  },
  mixins: [ notifications, loader ],
  methods: {
    async exportData(params) {
      await this.load(async () => {
        await this.exportPhotosFunction(this.$route.params.uuid, {...params, type: this.type })
      })

      this.notifySuccess(null, this.$t('apps.photos.exportsent', { email: params.email }))
      this.$router.push({ name: this.photosListRouteName, params: { uuid: this.$route.params.uuid } });
    }
  }
}
</script>
