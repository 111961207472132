var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ExportPhotosPage", {
    attrs: {
      "photos-list-route-name": _vm.photosListRouteName,
      "export-photos-function": _vm.exportPhotosFunction,
      type: this.type
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }